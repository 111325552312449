import Vue from 'vue';
import VueRouter from 'vue-router';
import LandingCopy from '../views/LandingCopy.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/log',
        name: 'landing',
        component: LandingCopy
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    }
];

const router = new VueRouter({
    routes
});

function checkACookieExists()
{
    return document.cookie
        .split(';')
        .some(item => item.trim().startsWith('authcookie='));
}

router.beforeEach((to, from, next) =>
{
    if (to.matched.some(record => record.meta.requiresAuth))
    {
        if (!checkACookieExists())
        {
            next({
                path: '/log'
            });
        }
        else
        {
            next();
        }
    }
    else
    {
        next();
    }
});

export default router;
