<template>
    <v-app>
        <SnackBar/>
        <router-view/>
    </v-app>
</template>
<script>
import SnackBar from './components/notification/SnackBar';

export default {
    components: {
        SnackBar
    }
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    position: absolute;
    margin-top: 100px;
    margin-left: 50%;
    z-index: 3000;
    width: 200px;
    background-color: white;
    border-radius: 50px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
