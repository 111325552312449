<template>
    <div class="w-100 h-100">
        <nav class="navbar navbar-light static-top">
            <div class="container mb-0 mt-0 p-0">
                <img src="@/assets/EDII_by_INOVEFA.png" style="width:100px;"/>
                <div style="margin-left:20px; font-size:30px; color: #ce0538">
                    <a class="gothamerbold redinovefa" style="color: #ce0538 !important" @click="scrollToCTA()"
                    ><b>PROFITEZ D'UN ESSAI GRATUIT</b></a
                    >
                </div>
                <span class="gothamerbold formacc2 p-2" style="font-size:20px;"
                ><font-awesome-icon
                  style="margin-right:8px;font-size: 16px;"
                  icon="phone"
                />01 86 95 19 23</span
                >
            </div>
        </nav>

        <!-- Masthead -->
        <header class="masthead text-center">
            <div class="overlay"></div>
            <div class="container">
                <div class="row" style="max-height:390px;">
                    <div class="col-xl-9 mx-auto" style="height:150px;">
                    </div>
                    <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
                        <form>
                            <div class="form-row" v-if="!engaged">
                                <div
                                  class="col-12 col-md-7 mb-2 mb-md-0"
                                  style="text-align:justify;font-size:18px;"
                                >
                                    <div class="row">
                                        <div class="col-6 mx-auto">
                                            <a class="btn btn-primary" @click="engaged = !engaged"
                                            >Se connecter</a
                                            ><br/>
                                            <a class="linkacc" @click="scrollToCTA()"
                                            >Pas encore de compte ?</a
                                            >
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                            <div class="form-row" v-else>
                                <div class="col-12 col-md-8 mx-auto">
                                  <b-form-input
                                      :state="username_state"
                                      autocomplete="username"
                                      v-model="username"
                                      type="email"
                                      class="mt-2 mb-2 form-control form-control-md"
                                      placeholder="Indiquez votre adresse email"
                                    ></b-form-input>
                                    <button
                                      type="submit"
                                      :disabled="loading"
                                      @click.prevent="login()"
                                      class="mt-2 mb-0 btn btn-block btn-md inobtn"
                                    >
                                        Recevoir mon lien de connexion par mail
                                    </button
                                    >
                                    <br/>
                                    <a class="linkacc" @click="scrollToCTA()"
                                    >Pas encore de compte ?</a
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </header>

        <section class="testimonials bg-light text-center">
            <div class="container">
                <div class="row">
                    <div class="col-8 mx-auto gothamer ino" style="font-size: 20px;">
                        <p>
                            EDii est un outil d'analyse interactif qui centralise un maximum
                            d’informations et de préconisations sur des données
                            démographiques, sociologiques, urbaines et immobilières afin de
                            faciliter un premier niveau d’analyse sur tout projet immobilier.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Icons Grid -->
        <section class="features-icons bg-light text-center">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <img
                              :src="require('@/assets/report1.png')"
                              style="height:100px; width:100px;margin-bottom:40px;"
                            />

                            <p class="lead gothamer ino mb-0">
                                Des données inclusives et détaillées pour se positionner
                                rapidement sur vos projets !
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <img
                              :src="require('@/assets/brain1.png')"
                              style="height:100px;width:100px;margin-bottom:40px;"
                            />

                            <p class="lead gothamer ino mb-0">
                                Une alliance unique entre des algorithmes puissants et
                                l’expertise métier d’INOVEFA.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                            <img
                              :src="require('@/assets/team1.png')"
                              style="height:100px;width:100px;margin-bottom:40px;"
                            />
                            <p class="lead gothamer ino mb-0">
                                Un outil interactif : simulateurs, chat en direct, évolution
                                régulière... Les experts d’INOVEFA ne sont jamais loin de vous !
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="showcase">
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-6 order-lg-1 text-white showcase-img">
                        <b-embed
                          type="iframe"
                          aspect="16by9"
                          src="https://www.youtube.com/embed/QrkpIph96CQ"
                          allowfullscreen
                        ></b-embed>
                    </div>

                    <div class="col-lg-6 order-lg-2 my-auto showcase-text p-4">
                        <h2 class="gothamerbold">Prise en main</h2>
                        <p class="lead gothamer mb-0">
                            La prise en main de EDii est facilit&eacute;e par son interface
                            ludique et intuitive. Visionnez notre vid&eacute;o de
                            pr&eacute;sentation pour d&eacute;couvrir rapidement ses
                            fonctionnalit&eacute;s.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section id="cta" class="call-to-action text-center mastfoot">
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-9 mx-auto" style="text-align:center;">
                        <h2 class="mb-6 gothamerbold">
                            Pas encore connect&eacute; sur EDII ?
                        </h2>
                        <h2 style="margin-bottom: 50px; margin-top:20px;">
                          <span
                            class="gothamerbold formacc2 p-2"
                            style="font-size:24px; border-radius: 5px;"
                          >PROFITEZ D'UN ESSAI GRATUIT</span
                          >
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-5 pl-4 pb-4" style="text-align:left;">
                        <h2 class="mb-2 gothamerbold">
                            Demandez d&egrave;s maintenant vos acc&egrave;s !
                        </h2>
                        <h3 class="gothamer">
                            Un expert INOVEFA prendra contact avec vous
                        </h3>
                        <br/><br/>
                        <h3 class="gothamer">ou appelez le</h3>
                        <span class="gothamerbold formacc2 p-2" style="font-size:24px;"
                        >01 86 95 19 23</span
                        >
                    </div>
                    <div class="col-xl-5 mx-auto formacc1">
                        <form>
                            <div class="form-row">
                                <div class="col-12 mb-2 mb-md-0">
                                    <h5 style="text-align:left;margin-bottom:0;">Nom *</h5>
                                    <b-form-input
                                      :state="acces_name_state"
                                      v-model="acces_name"
                                      style="width:100%;margin-bottom:10px;"
                                      type="text"
                                      class="form-control form-control-lg"
                                      placeholder="Entrez votre nom..."
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12 mb-2 mb-md-0">
                                    <h5 style="text-align:left;margin-bottom:0;">Email *</h5>
                                    <b-form-input
                                      :state="acces_mail_state"
                                      v-model="acces_mail"
                                      style="width:100%;margin-bottom:10px;"
                                      type="email"
                                      class="form-control form-control-lg"
                                      placeholder="Entrez votre email..."
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12 mb-2 mb-md-0">
                                    <h5 style="text-align:left;margin-bottom:0;">
                                        T&eacute;l&eacute;phone *
                                    </h5>
                                    <b-form-input
                                      :state="acces_tel_state"
                                      v-model="acces_tel"
                                      style="width:100%;margin-bottom:10px;"
                                      type="text"
                                      class="form-control form-control-lg"
                                      placeholder="Entrez votre numero de téléphone..."
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12 mb-2 mb-md-0">
                                    <h5 style="text-align:left;margin-bottom:0;">
                                        Soci&eacute;t&eacute; *
                                    </h5>
                                    <b-form-input
                                      :state="acces_societe_state"
                                      v-model="acces_societe"
                                      style="width:100%;margin-bottom:10px;"
                                      type="text"
                                      class="form-control form-control-lg"
                                      placeholder="Entrez votre nom de société..."
                                    ></b-form-input>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12" style="text-align: justify;font-size: 12px">
                                    INOVEFA collecte et traite vos données personnelles aux fins
                                    de répondre à vos demandes d'acc&egrave;s au service, de mise
                                    en contact et/ou pour vous faire parvenir des informations sur
                                    EDii, notre actualité et la protection des données
                                    personnelles. Veuillez prendre connaissance de notre Politique
                                    de confidentialité pour plus d'informations sur les
                                    traitements de données que nous réalisons et les droits dont
                                    vous disposez sur vos données personnelles.
                                </div>
                            </div>
                            <vue-recaptcha
                              sitekey="6LfXnj4aAAAAAMNeOVXZ0LpYZgBA-Nc7krd4I9sh"
                              @verify="onVerify"
                              @expired="onExpired"
                              :loadRecaptchaScript="true"
                            >
                            </vue-recaptcha>
                            <div class="form-row  mt-4">
                                <div class="col-8 mx-auto mb-2 mb-md-0" v-if="!hide_demand">
                                    <button
                                      type="submit"
                                      class="btn btn-block btn-lg inobtn"
                                      @click.prevent="captcha_token != null ? demande_acces() : erreur()"
                                    >
                                        Demander
                                    </button>
                                </div>

                                <div class="col-8 mx-auto mb-2 mb-md-0 gothamer" v-else>
                                    <font-awesome-icon
                                      style="font-size: 35px;"
                                      class="inored"
                                      icon="check"
                                    />
                                    <br/>
                                    Vous allez &ecirc;tre recontact&eacute; tr&egrave;s
                                    prochainement, merci de l'inter&ecirc;t que vous portez
                                    &agrave; EDII.
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 h-100 text-center text-lg-left my-auto">
                        <ul class="list-inline mb-2">
                            <li class="list-inline-item">
                                <a class="foot_link" @click="modallegal = true" href="#"
                                >Mentions l&eacute;gales</a
                                >
                            </li>
                            <li class="list-inline-item">&sdot;</li>
                            <li class="list-inline-item">
                                <a class="foot_link" @click="modalcgu = true" href="#"
                                >Conditions d'utilisation</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 h-100 text-center text-lg-right my-auto">
                        <p class="text-white small mb-4 mb-lg-0 mt-3">
                            &copy; INOVEFA 2023. Tous droits r&eacute;serv&eacute;s.
                        </p>
                    </div>
                </div>
            </div>
        </footer>

        <b-modal
          id="modal-legal"
          v-model="modallegal"
          :header-bg-variant="'#003340'"
          :header-text-variant="'#f8f9fa'"
          :body-bg-variant="'#f8f9fa'"
          :body-text-variant="'#003340'"
          :footer-bg-variant="'#f8f9fa'"
          :footer-text-variant="'#003340'"
          size="lg"
          ok-only
          scrollable
          title="Mentions légales"
        >
            <h3>Éditeur du site</h3>
            <p class="my-4">
                Le site internet www.inovefa-edii.com est un site édité par le groupe
                INOVEFA repr&eacute;sent&eacute; par la société SAS FINANCIERE DU
                MONTPARNASSE au capital de 2 476 050,00 € dont le siège social est
                situé 85 Avenue Pierre Grenier - 92100 Boulogne Billancourt, dont le
                représentant légal est Monsieur BISSON Benjamin, inscrite au RCS du greffe de
                Nanterre sous le numéro 814 876 454,<br/>
                <br/>
                connue ci-après par les mentions "INOVEFA" ou “Groupe INOVEFA”.<br/>
                <br/>
                Toute reproduction totale ou partielle de ce site sans autorisation
                préalable du Groupe INOVEFA est interdite et constituerait une
                contrefaçon.
            </p>
            <br/>
            <h3>Nous contacter</h3>

            <p class="my-4">
                Il est possible de contacter le Groupe INOVEFA par les moyens suivants
                :<br/>
                <br/>
                &sdot; par téléphone au 01 86 95 19 23 du lundi au vendredi de 8h à 19h
                (appel non surtaxé)<br/>
                &sdot; par courrier électronique via l’adresse mail
                contact@inovefa.com<br/>
                &sdot; par lettre simple à l’adresse de son siège social : 85 Avenue
                Pierre Grenier - 92100 Boulogne Billancourt<br/>
                <br/>
                Pour contacter le DPO vous pouvez utiliser les moyens
                pr&eacute;cic&eacute;s ci-dessus ou par mail &agrave; l'adresse
                dpo@inovefa.com.<br/>
            </p>
            <br/>

            <template v-slot:modal-footer="{ ok }">
                <b-button class="btn inobtn" @click="ok()">
                    Fermer
                </b-button>
            </template>
        </b-modal>

        <b-modal
          id="modal-cgu"
          v-model="modalcgu"
          :header-bg-variant="'#003340'"
          :header-text-variant="'#f8f9fa'"
          :body-bg-variant="'#f8f9fa'"
          :body-text-variant="'#003340'"
          :footer-bg-variant="'#f8f9fa'"
          :footer-text-variant="'#003340'"
          size="lg"
          ok-only
          scrollable
          title="Conditions générales d'utilisation"
          style="text-align: justify;"
        >
            <h2>EDII – Expertise Digitale Immobilière Interactive</h2>
            <h3>Conditions générales d’utilisation</h3>

            <p>Version : 1.0.14 publi&eacute;e le 1 avril 2020</p>

            <p>
                EDII – Expertise Digitale Immobilière Interactive a pour vocation de
                centraliser le maximum d’informations et de préconisations sur des
                données démographiques, sociologiques, urbaines et immobilières afin de
                faciliter un premier niveau d’analyse sur tout projet immobilier.
            </p>

            <p>
                Les informations et préconisations contenues dans cette application ne
                sauraient donc être ni exhaustives ni suffisantes pour s’engager de
                manière ferme et définitive sur un projet de promotion immobilière.
            </p>

            <p>
                Les informations communiquées par l'application EDII by INOVEFA n'ont
                pas vocation à se substituer à une expertise approfondie par notre
                service. Les données communiquées par ce biais sont celles fournies en
                l'état à la date indiquée par l'éditeur, ne sont donc pas actualisées et
                peuvent ainsi avoir évolué. Ces données ne sont donc pas contractuelles
                et ne sauraient engager la responsabilité d'INOVEFA.
            </p>

            <br/>
            <h3>Précisions sur l’utilisation des données fournies :</h3>

            <h4>Zonage et précision géographique</h4>

            <p>
                Les données et préconisations sont fournies au niveau de la zone IRIS
                (Ilots Regroupés pour l'Information Statistique), soit le plus petit
                niveau disponible sur les données fournies par l’INSEE.
            </p>

            <br/>
            <h3>Catégorie « Population »</h3>
            <p>
                Cette catégorie concentre des données traitées sur l’évolution et le
                profil démographique de chaque zone IRIS comparée à la commune. Ces
                données sont retraitées à partir de données issues des recensements
                effectués par l’INSEE et sont mises à jour annuellement en fonction des
                données INSEE les plus récentes à disposition.
            </p>

            <h4>Précision sur la capacité d’achat au m2</h4>
            <p>
                La capacité d’achat au m2 se calcule sur la base de la capacité
                d’endettement des ménages du secteur sur 25 ans en fonction de leurs
                revenus déclarés annuellement et d’un projet de 55 m2 moyens. Les
                hypothèses de taux d’endettement et les taux appliqués sont remis à jour
                mensuellement. Attention ces données ne prennent pas en compte un
                éventuel apport ou l’effet de dispositif d’aides type PTZ. Par ailleurs,
                cet indicateur se doit d’être manié avec précautions. Par exemple, la
                capacité d’achat peut être très élevée dans certains quartiers dominés
                par l’habitat individuel, ces quartiers sont souvent peu adaptés à un
                développement d’offre en collectif, ce qui peut expliquer d’importants
                écarts entre le prix en collectif préconisé et la capacité d’achat de la
                zone considérée.
            </p>

            <br/>
            <h3>Catégorie « Services »</h3>

            <p>
                La rubrique services regroupe les différents points d’intérêt et de
                vigilance pouvant influer sur la qualité d’un tènement foncier.<br/>
                Les distances par rapport au foncier étudié sont exprimées en minutes à
                pied à une vitesse de 3,5 Km/Heure.<br/>
                Les informations contenues dans cette rubrique ne sauraient être
                exhaustives et doivent se vérifier par une visite sur le terrain.<br/>
            </p>

            <h3>Catégorie « Données Immobilières »</h3>

            <h4>Parc immobilier</h4>
            <p>
                Cette catégorie concentre des données traitées sur l’évolution et le
                profil du parc immobilier de chaque zone IRIS comparée à la commune. Ces
                données sont retraitées à partir de données issues des recensements
                effectués par l’INSEE et sont mises à jour annuellement en fonction des
                données INSEE les plus récentes à disposition.
            </p>
            <h4>Préconisations</h4>
            <p>
                Les préconisations fournies proviennent de l’analyse exclusive d’INOVEFA
                Expertise, basées sur plus de 5000 missions de conseil et études de
                marché réalisées depuis 2012. La période d’édition de ces préconisations
                est clairement indiquée sur chaque secteur étudié. INOVEFA Expertise
                s’efforce de tenir à jour le plus régulièrement possible ses
                préconisations sur chaque zone.<br/>
                Sur chaque zone, est donc fournie :<br/>
                • Une fourchette de prix envisageable, toujours exprimée en TVA 20%
                parking inclus pour un rythme classique de 3 ventes / mois. La ligne «
                TVA réduite » apparaît lorsqu’une partie ou toute la zone est éligible
                au dispositif au moment de l’édition des préconisations, les prix
                indiqués s’entendent en revanche toujours en TVA 20% parking inclus.<br/>
                • Une analyse textuelle du secteur, de ses qualités, de ses défauts et
                des points d’attention particuliers.<br/>
                • Une préconisation sur la SHAB moyenne adaptée au secteur,<br/>
                • L’avis d’INOVEFA Expertise sur le potentiel investisseur de la zone,
                qui peut guider le recours plus ou moins important aux réseaux de
                défiscalisation,<br/>
                • Un niveau de prestation envisageable sur la zone considérée.<br/>
                Ces préconisations ne sont en revanche qu’une première approximation du
                prix envisageable sur un secteur donné et ne peuvent engager la
                responsabilité d’INOVEFA Expertise sur leur véracité. En effet, des
                préconisations précises et opérationnelles sur un projet donné
                nécessitent une analyse précise et fine dudit projet, prenant en compte
                une multitude de facteurs impossible à couvrir par notre application
                :<br/>
                • Analyse précise de l’environnement actuel et à venir du projet,<br/>
                • Taille de l’opération,<br/>
                • Politique commercial de l’opérateur,<br/>
                • Canaux de distribution,<br/>
                • Calibrage du rythme de vente,<br/>
                • Typologies,<br/>
                • Surfaces,<br/>
                <br/>
                • Spécificités architecturales et/ou programmatiques,<br/>
                • Horizon temporel de lancement...
            </p>

            <h4>Permis de construire</h4>
            <p>
                Dans cette rubrique, nous reprenons les permis de construire de plus de
                10 logements déposés au cours des 12 derniers mois avec toutes les
                informations dont nous disposons sur ces derniers. Attention, il s’agit
                de retraitements de listes publiques qui peuvent se révéler incomplètes
                et ne sont diffusés qu’à N-2 mois (les deux derniers mois ne sont pas
                couverts).
            </p>

            <h4>Marché de l’ancien</h4>
            <p>
                Dans cette rubrique, les dernières transactions recensées par la base
                DVF sur les 24 derniers mois sont retraitées, nettoyées et sélectionnées
                pour donner une idée pertinente du marché de l’ancien dans le secteur.
                Attention, les montants des transactions recensées doivent être analysés
                à l’aune de la qualité du bien vendu et ne peuvent représenter un
                indicateur de marché en soi.
            </p>
            <h4>Programmes neufs</h4>
            <p>
                Cette section recense les programmes immobiliers neufs passés et en
                cours de commercialisation. Ces données proviennent à la fois des bases
                internes d’INOVEFA Expertise et de sources publiques (site d’annonces,
                site de promoteurs...) et sont mises à jour de manière mensuelle. EDII
                n’ayant pas vocation a être une base de données recensant les prix des
                programmes immobiliers neufs, aucune information de prix de vente ne
                sera communiquée. Notre conviction est qu’un prix de vente doit
                s’analyser finement programme par programme dans le cadre d’une étude
                complète et ne représente pas un élément fiable pour se positionner a
                priori.
            </p>

            <h4>ZUS / QPV – éligibilité à la TVA réduite</h4>
            <p>
                Cette section recoupe tous les cas d’éligibilité à la TVA réduite avec
                des informations sur les conditions applicables à chaque zone.
                Attention, il sera nécessaire de valider l’éligibilité à la TVA réduite
                d’un projet donné par une analyse parcellaire précise, confirmée par un
                rescrit fiscal auprès du service des impôts territorialement compétent.
            </p>

            <br/>
            <h3>Catégorie « simulateurs »</h3>

            <h4>Mensualités</h4>
            <p>
                Ce simulateur permet de déterminer a priori une mensualité de
                remboursement sur un projet donné. Les taux d’intérêt estimé (TAEG) sont
                remis à jour mensuellement. Attention, ce simulateur ne prévaut en rien
                d’une simulation bancaire ou d’une offre de prêt réalisée par un
                établissement bancaire et est donnée à titre indicative.
            </p>

            <h4>Ménages solvables</h4>
            <p>
                Ce simulateur se base sur les revenus déclarés par déciles par commune
                retraités par les algorithmes d’EDII. En fonction d’une surface moyenne
                et d’un prix, l’algorithme renvoie une fraction de la population a
                priori solvable en fonction d’un % d’apport envisagé. Cet indicateur ne
                saurait assurer l’écoulement d’un projet précis sur une commune donnée.
            </p>

            <h4>Pinel</h4>
            <p></p>
            <p>
                Ce simulateur renvoie une estimation du loyer Pinel en fonction de la
                commune, d’une surface habitable et de surfaces annexes communiquées.<br/>
                Pour rappel, La surface prise en compte s’entend de la surface habitable
                augmentée de la moitié de la surface des annexes, dans la limite de 8 m2
                par logement. Les emplacements de stationnement et de garage ne sont pas
                pris en compte, sous réserve d’une mesure de tempérament prévue pour les
                maisons individuelles.<br/>
                Les surfaces annexes sont celles réservées à l'usage exclusif de
                l'occupant du logement et dont la hauteur sous plafond est au moins
                égale à 1,80 m : caves, sous-sols, remises, ateliers, séchoirs et
                celliers extérieurs au logement, resserres, combles et greniers
                aménageables, balcons, loggias et vérandas et, dans la limite de 9 m2,
                les parties de terrasses accessibles en étage ou aménagées sur ouvrage
                enterré ou à moitié enterré. Ces dernières s’entendent notamment de
                celles situées en totalité sur le toit d’un logement (cas des immeubles
                construits en gradins dans lesquels la dalle qui constitue la toiture
                d’une partie d’un logement sert de terrasse au logement supérieur) ou
                sur le toit des dépendances immédiates et nécessaires d’un logement
                (dépendances ou parking souterrain par exemple) et dont le locataire a
                la jouissance exclusive. Tel n’est pas le cas, en revanche, des
                terrasses carrelées en rez-de-jardin d’une maison individuelle.
            </p>

            <template v-slot:modal-footer="{ ok }">
                <b-button class="btn inobtn" @click="ok()">
                    Fermer
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

function validateEmail(email)
{
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

import axios from 'axios';

export default {
    name: 'landing',
    components: {
        VueRecaptcha
    },
    data()
    {
        return {
            modallegal: false,
            modalcgu: false,
            acces_name: null,
            acces_mail: null,
            acces_tel: null,
            acces_societe: null,
            loading: false,
            engaged: true,
            username: null,
            hide_demand: false,
            passwd: null,
            captcha_script_url: 'https://www.google.com/recaptcha/api.js',
            captcha_token: null
        };
    },
    computed: {
        username_state()
        {
            if (this.username == null) return null;

            return this.username.length > 0 && validateEmail(this.username);
        },
        passwd_state()
        {
            if (this.passwd == null) return null;

            return this.passwd.length >= 3;
        },
        acces_name_state()
        {
            if (this.acces_name == null) return null;

            return this.acces_name.length > 0;
        },
        acces_mail_state()
        {
            if (this.acces_mail == null) return null;

            return this.acces_mail.length > 0 && validateEmail(this.acces_mail);
        },
        acces_tel_state()
        {
            if (this.acces_tel == null) return null;

            return this.acces_tel.length > 5;
        },
        acces_societe_state()
        {
            if (this.acces_societe == null) return null;

            return this.acces_societe.length > 0;
        }
    },
    methods: {
        scrollToCTA()
        {
            let el = document.getElementById('cta');
            if (el) el.scrollIntoView({behavior: 'smooth'});
        },
        erreur()
        {
            alert('Veuillez cocher la case du Captcha pour valider vos informations');
        },
        onVerify: function (response)
        {
            this.captcha_token = response;
        },
        onExpired: function ()
        {
        },
        showModal(ref)
        {
            this.$refs[ref].show();
        },
        hideModal(ref)
        {
            this.$refs[ref].hide();
        },
        toggleModal(ref)
        {
            this.$refs[ref].toggle('#toggle-btn');
        },
        demande_acces()
        {
            this.hide_demand = true;

            var payload = {
                name: this.acces_name,
                email: this.acces_mail,
                tel: this.acces_tel,
                societe: this.acces_societe
            };

            if (
              this.acces_name == null ||
              this.acces_mail == null ||
              this.acces_tel == null ||
              this.acces_societe == null
            )
            {
                this.acces_name = this.acces_name == null ? '' : this.acces_name;
                this.acces_mail = this.acces_mail == null ? '' : this.acces_mail;
                this.acces_tel = this.acces_tel == null ? '' : this.acces_tel;
                this.acces_societe =
                  this.acces_societe == null ? '' : this.acces_societe;
                this.hide_demand = false;
                return false;
            }

            if (
              this.acces_name_state &&
              this.acces_mail_state &&
              this.acces_tel_state &&
              this.acces_societe_state
            )
            {
                axios
                  .post(`api/auth/access`, payload)
                  .then(() => { return true })
                  .catch(() => { return false });
            }
            else
            {
                this.hide_demand = false;
            }
        },
        login()
        {
            let form = {
                email: this.username,
            };
            axios
              .post(`api/auth/signin`, form, {
                  withCredentials: true
              })
              .then(() =>
              {
                  this.$store.commit('set_alert_snackbar', {
                      content: 'Votre lien de connexion sécurisé a été envoyé sur votre boîte mail',
                      color: 'green',
                      type: 'success'
                  });
              })
              .catch(err =>
              {
                  this.$store.commit('set_alert_snackbar', {
                      content: err.response.data.message,
                      color: 'red',
                      type: 'error'
                  });
              });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.my-4 {
    text-align: justify;
}

.static-top {
    max-height: 80px;
    background-color: #f8f9fa;
}

.linkacc:hover {
    background-color: $C_expertise_lighter !important;
}

.linkacc {
    color: #f8f9fa !important;
    background-color: $C_expertise !important;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.redinovefa {
    color: $C_expertise_lighter !important;
}

.inobtn {
    background-color: $C_ino !important;
    color: #fff !important;
}

.inored {
    color: $C_ino;
}

.ino {
    color: $C_expertise;
}

.foot_link {
    color: #fff !important;
}

.footer {
    color: #fff !important;
    background-color: $C_expertise;
}

.formacc1 {
    color: #fff;
    background-color: $C_expertise_lighter;
    padding: 15px;
}

.formacc2 {
    background-color: $C_ino;
    color: #fff;
    padding: 15px;
}

.masthead {
    background-image: url('../assets/headbg.png');
    background-color: $C_expertise_alt;
    background-position: center;
    color: #fff;
}

.bg_ino {
    background-color: #013240 !important;
}

.mastfoot {
    color: #fff !important;
    background-color: $C_expertise;
}

.gothamer {
    font-family: 'Gotham', sans-serif;
}

.gothamerbold {
    font-family: 'GothamBold', sans-serif;
}

.slide-fade-enter-active {
    transition: all 0.3s ease;
    opacity: 1;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    opacity: 0;
}

.slide-fade-enter, .slide-fade-leave-to
{
    transform: translateX(10px);
    opacity: 0;
}
</style>
