export var config_Data_array = (element, filteredNames, type_geo) =>
{
    let temp_object = {};
    switch (type_geo)
    {
        case 'choropleth':
            for (const [key, value] of Object.entries(element[filteredNames][0]))
                temp_object[key] = value;

            temp_object['id'] = element.id;
            temp_object['code'] = element.code_iris != null ? element.code_iris : element.insee_com;

            if (!element.nom_iris)
                temp_object['key_name'] = element.nom_com;
            else if (element.commune)
                temp_object['key_name'] = `<strong>${element.commune.nom_com}</strong><br/>${element.nom_iris}`;
            else
                temp_object['key_name'] = element.nom_iris;

            return temp_object;
    }
};
