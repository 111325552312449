<template>
    <v-alert
      v-model="show"
      dense
      :type="type"
      dismissible
      prominent
      transition="scroll-y-transition"
    >
        {{ message }}
    </v-alert>
</template>

<script>
export default {
    data()
    {
        return {
            timeout: 3000,
            show: false,
            message: '',
            color: '',
            type: undefined
        };
    },

    created()
    {
        this.$store.subscribe((mutation, state) =>
        {
            if (mutation.type === 'set_alert_snackbar')
            {
                this.message = state.alert_snackbar.content;
                this.color = state.alert_snackbar.color;
                this.type = state.alert_snackbar.type;
                this.show = true;
            }
        });
    },
    watch: {
        show: function ()
        {
            setTimeout(() =>
            {
                this.show = false;
            }, 3000);
        }
    }
};
</script>

<style scoped>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    z-index: 50;
}
</style>
